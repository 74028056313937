*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  height: 100%;
  
}

@media (max-width: 768px) and (min-width: 425px){
.medium-heading{
  font-size: 28px;
}
.small-text{
  font-size: 14px;
  }
}
 @media (max-width: 425px) and (min-width: 375px) {
.marginTop{
  margin-top: 1.5rem;
}
.small-text{
font-size: 16px;
}
.small-heading{
  font-size: 28px;
}
.classTop{
  margin-top: 15px;
}
.circle{
font-size: 28px !important;
}
}

@media (width: 430px) {
  .marginTop{
    margin-top: 1.5rem;
  }
  .circle{
    font-size: 28px !important;
}
.small-text{
  font-size: 16px;
  }
  .small-heading{
    font-size: 28px;
  }
}

@media (max-width: 375px) {
  .circle{
    font-size: 26px !important;
}
.small-text{
  font-size: 16px;
  }
  .small-heading{
    font-size: 26px !important;
  }
  .logo{
    width: 220px !important;
  }
}
@media (width: 390px) {
  .small-heading{
    font-size: 27px !important;
  }
}

.circle {
  height: 70px;
  font-size: 40px;
  display: block;
  animation: spin_words 10s infinite;
}

@keyframes spin_words {
  10% {
    transform: translateY(-110%);
  }
  30% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(-212%);
  }
}
.words {
  overflow: hidden;
}

.date-icon-color::-webkit-calendar-picker-indicator {
  font-size: 26px;
  filter: invert(60%) sepia(95%) saturate(1105%) hue-rotate(166deg)
    brightness(89%) contrast(99%);
}

input[type="file"] {
  display: none;
}
.phoneInput {
  width: 100%;
}

.admin-scroll-bar::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.admin-scroll-bar::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #ffffff;
}

.admin-scroll-bar::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

.admin-scroll-bar::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

.admin-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #4fd1c5;
}

.admin-scroll-bar::-webkit-scrollbar-thumb:active {
  background-color: #4fd1c5;
}

/* scrolll-bar */
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #ffffff;
}

::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #2dd0a5;
}

::-webkit-scrollbar-thumb:active {
  background-color: #2dd0a5;
}

/* Loader */
/* styles.css */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fancy-loader {
  @apply border-8 border-t-8 border-gray-300 border-t-blue-500 rounded-full;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.overlay {
  @apply fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center;
  z-index: 50;
}

/* carousel------------- */
.imageSlider{
  background: url(./assets/whatwe.png);
  width: 60%;
  height: auto;

}


.transition-opacity {
  transition: opacity 1s ease-in-out;
}
.opacity-100 {
  opacity: 1;
}
.opacity-0 {
  opacity: 0;
}
